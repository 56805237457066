import React, { useState } from 'react'
import Button from 'react-bootstrap/button';


const SpiceForm = props =>  {

    const [name, setName] = useState('')
    const [expiration_date, setExpirationDate] = useState('')


    const handleCreateSpice = (spiceData) => {
        fetch(`https://meredithbashert.com/spicetracker-backend/spices`, {
            body: JSON.stringify(spiceData),
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }).then(createdSpice => {
            return createdSpice.json()
        })
        .then(json => {
            props.setSavedSpices(json)
        })
        .catch(err=>console.log(err))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const spiceinfo = {
            name:name,
            expiration_date:expiration_date
        }
        handleCreateSpice(spiceinfo);
        props.handleClose()
        setName('')
        setExpirationDate('')
    }

    return (
        <form onSubmit={handleSubmit}>
        <label>
        Spice:
        <input type="text" id="name" value={name} onChange={e => setName(e.target.value)} required/>
        </label>
        <br/>
        <label>
        Expiration Date:
        <input type="date" id="date" value={expiration_date} onChange={e => setExpirationDate(e.target.value)} required/>
        </label>
        <br/>
        <Button variant="outline-success" type="submit" onClick={props.handleSubmit} size="sm" className="modal-button">Put in the Cabinet</Button>
        <Button variant="outline-success" onClick={props.handleClose} size="sm">Cancel</Button>
        </form>
    )
}


export default SpiceForm
